import { h } from 'preact';
import { Link } from 'preact-router/match';

import style from './style.css';

const Header = ({proVersion}) => (

<header className={style.header}>
	<h1>
		<a href="/" class="uppercase">Noise &amp; Gradient</a>
	</h1>
	<p>Generate your very own trendy textured background</p>
	<div className={style.proNotice}>
		{ proVersion ? <span>Welcome Pro! Read the <a href="#faqs">FAQs</a></span> : <span>Check out the <Link href="/pro-version">Pro Version!</Link></span>}
	</div>
</header>
	
);

export default Header;
