import { h } from 'preact';
import { Router } from 'preact-router';
import Footer from './footer';
import Header from './header';
// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import ProVersion from '../routes/pro-version';
/** @jsx h */

const Error = ({ type, url }) => (
	<>
		<Header proVersion={false} />
		<section class="error">			
			<h2>{type} Error!</h2>
			<p>It looks like we hit a snag. That page doesn't exist!</p>
			<pre>{url}</pre>
		</section>
	</>
);


const App = () => (
	<div id="app">

			<Router>
				
				<Home path="/" />
				<ProVersion path="/pro-version" />

				<Error type="404" path="/test-error" /> 

				<Error type="404" default />
			</Router>
			<Footer />
	</div>
)


export default App;
