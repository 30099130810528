import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

const Socials = () => {
	const list = [
		{
			name: "my website",
			imageSource: "/assets/small logo pink.png",
			link: "https://clarabdevelopment.com"
		},
		{
			name: "twitter",
			imageSource: "/assets/twitter bird.png",
			link: "https://twitter.com/clarabellum"
		},
		{
			name: "instagram",
			imageSource: "/assets/small insta.png",
			link: "https://instagram.com/clarabdevelopment"
		}
	]

	return list.map((element) => {
		return (
			<a key={element.name} href={element.link} target="_blank" title={element.name}>
				<img src={element.imageSource} className={style.icon} />
			</a>
		)
	})
}

const Footer = () => {
	
	
	return (
		<footer className={style.footer}>
			<p className={style.credit}>
				Many many thanks to: <a href="https://p5js.org/" target="_blank">p5.js</a> and the <a href="https://processing.org/" target="_blank">Processing</a> community, everyone who helped me debug, and <a href="http://byteblacksmith.com/improvements-to-the-canonical-one-liner-glsl-rand-for-opengl-es-2-0/" target="_blank">this one blog post from 2013 about sin() functions on different GPUs</a>, without which this generator would be absolutely useless on mobile devices.
			</p>
			<p className={style.credit}>If you want, you can <a target="_blank" href="https://www.buymeacoffee.com/clarabdev">buy me a coffee</a>! You also might like the <a href="/pro-version">pro version</a>!</p>

			<p className={style.love}>Made with love by Clara B. Development</p>
			{ Socials() }			

		</footer>	
	)
};

export default Footer;
